

.date-range-with-no-edit-icon {
  .PrivateDateRangePickerToolbar-penIcon {
    display: none;
  }
}

.date-with-no-edit-icon {
  .PrivateDatePickerToolbar-penIcon {
    display: none;
  }
}

// @media only screen
// and (max-width: 1540px) {

//   .drawer-mobile {
//     position: absolute !important;   
//     height: 100vh;
//   }
// }

/* Dashbiard navlink active */
.page-title{
  &::after{
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    margin: 0.19285em 0 0.423em;
    border-radius: 4px;
  }

  &.accent-primary::after{
    background: #0e1d34;
  }
  &.accent-primary-dark::after{
    background: #3E4A5C;
  }
  &.accent-primary-light::after{
    background: #3E4A5C;
  }
  &.accent-primary-lighter::after{
    background: #eaf0f0;
  }

  &.accent-secondary::after{
    background: #C74634;
  }
  &.accent-secondary-dark::after{
    
    background: #b81700;
  }
  &.accent-secondary-light::after{
    background: #ee6350;
  }
  &.accent-secondary-lighter::after{
    background: #fdafa4;
  }
}
.header-menu-link{
  opacity: 0.8;
}
.header-menu-link.active{
  // border-bottom: 2px solid #000;
  font-weight: 900;
  color: #C74634;
  opacity: 1;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.app-page-container{
  .api-progress-container{
    visibility: hidden;
  }
  &.loading{
    pointer-events: none;
    position: relative;
    button[type='submit']{
      background-color: #c8c8c8;
      pointer-events: none;
    }

    .api-progress-container{
      visibility: visible;
    }

    .loading-wrapper{
      pointer-events: none;
      background-color: #f3f3f3;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: .5;
      z-index: 1;
    }
  }
}

.title-accent{
  &::after{
    content: '';
    display: block;
    width: 20px;
    height: 3px;
    margin: 0.19285em 0 0.423em;
    border-radius: 2px;
  }

  &.primary::after{
    background: #0e1d34;
  }
  &.primary-dark::after{
    background: #3E4A5C;
  }
  &.primary-light::after{
    background: #3E4A5C;
  }
  &.primary-lighter::after{
    background: #eaf0f0;
  }

  &.secondary::after{
    background: #C74634;
  }
  &.secondary-dark::after{
    
    background: #b81700;
  }
  &.secondary-light::after{
    background: #ee6350;
  }
  &.secondary-lighter::after{
    background: #fdafa4;
  }
  &.white::after{
    background: #ffffff;
  }
}

@media only screen
and (max-width: 1200) {
  .drawer-mobile {
    position: absolute !important;   
    height: 100vh;
  }
}

.simplebar-track{
  opacity: 0.4;
}

.readonly-form {
  .Mui-disabled:not(button) {
    -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
    color: rgba(0, 0, 0, 1);
  }
}

.inline-circular-progress {
  display: none;
  &.loading {
    display: flex;
  }
}
.font-wt100 {
  font-weight: 100 !important;
}
.font-wt200 {
  font-weight: 200 !important;
}
.font-wt300 {
  font-weight: 300 !important;
}
.font-wt400 {
  font-weight: 400 !important;
}
.font-wt500 {
  font-weight: 500 !important;
}
.font-wt600 {
  font-weight: 600 !important;
}
.font-wt700 {
  font-weight: 700 !important;
}
.font-wt800 {
  font-weight: 800 !important;
}
.font-wt900 {
  font-weight: 900 !important;
}
.login-logo img{
  width: 60px;
  height: auto;
}

#webpack-dev-server-client-overlay{
  display: none;
}